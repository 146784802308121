<template>
  <div class="customer-list">
    <h1 class="page-title">Müşteri Listesi</h1>
    <button @click="showAddCustomerModal" class="btn-add">Müşteri Ekle</button>
    <table class="customer-table">
      <thead>
      <tr>
        <th>ID</th>
        <th>Ad</th>
        <th>Soyad</th>
        <th>Kimlik No</th>
        <th>İşlemler</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="customer in customers" :key="customer.id">
        <td>{{ customer.id }}</td>
        <td>{{ customer.firstName }}</td>
        <td>{{ customer.lastName }}</td>
        <td>{{ customer.identificationNumber }}</td>
        <td>
          <button @click="editCustomer(customer)" class="btn-edit">Düzenle</button>
          <button @click="deleteCustomer(customer.id)" class="btn-delete">Sil</button>
        </td>
      </tr>
      <tr v-if="customers.length === 0">
        <td colspan="5" class="no-data">Müşteri bulunamadı.</td>
      </tr>
      </tbody>
    </table>

    <div v-if="showModal" class="modal">
      <div class="modal-content">
        <span @click="hideAddCustomerModal" class="close">&times;</span>
        <h2>Müşteri Ekle</h2>
        <form @submit.prevent="addCustomer" class="customer-form">
          <div class="form-group">
            <label for="newFirstName">Ad:</label>
            <input type="text" id="newFirstName" v-model="newCustomer.firstName" class="form-control" required />
          </div>
          <div class="form-group">
            <label for="newLastName">Soyad:</label>
            <input type="text" id="newLastName" v-model="newCustomer.lastName" class="form-control" required />
          </div>
          <div class="form-group">
            <label for="newIdentificationNumber">Kimlik No:</label>
            <input type="text" id="newIdentificationNumber" v-model="newCustomer.identificationNumber" class="form-control" required />
          </div>
          <button type="submit" class="btn btn-primary">Kaydet</button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'CustomerList',
  data() {
    return {
      customers: [],
      showModal: false,
      newCustomer: {
        firstName: '',
        lastName: '',
        identificationNumber: ''
      }
    };
  },
  mounted() {
    this.getCustomers();
  },
  methods: {
    getCustomers() {
      axios.get('http://neogoation.mertay.me/api/api/customer')
          .then(response => {
            this.customers = response.data;
          });
    },
    editCustomer(customer) {
      if (!customer.id) {
        console.error('Müşteri ID eksik.');
        return;
      }
      this.$router.push({ name: 'CustomerForm', params: { id: customer.id } });
    },
    deleteCustomer(id) {
      axios.delete(`http://neogoation.mertay.me/api/api/customer/${id}`)
          .then(() => {
            this.getCustomers();
          });
    },
    showAddCustomerModal() {
      this.showModal = true;
    },
    hideAddCustomerModal() {
      this.showModal = false;
    },
    addCustomer() {
      axios.post('http://neogoation.mertay.me/api/api/customer', this.newCustomer)
          .then(() => {
            this.getCustomers();
            this.showModal = false;
            this.newCustomer = {
              firstName: '',
              lastName: '',
              identificationNumber: ''
            };
          })
          .catch(error => {
            console.error('Müşteri ekleme başarısız:', error);
          });
    },
  },
};
</script>

<style scoped>
.customer-list {
  max-width: 800px;
  margin: 20px auto;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
}

.page-title {
  font-size: 24px;
  margin-bottom: 20px;
}

.customer-table {
  width: 100%;
  border-collapse: collapse;
}

.customer-table th,
.customer-table td {
  border: 1px solid #ddd;
  padding: 12px;
  text-align: left;
}

.customer-table th {
  background-color: #f2f2f2;
  font-weight: bold;
}

.btn-edit,
.btn-delete {
  padding: 8px 16px;
  cursor: pointer;
  border: none;
  background-color: #007bff;
  color: white;
  border-radius: 4px;
  font-size: 14px;
  transition: background-color 0.3s;
}

.btn-edit:hover,
.btn-delete:hover {
  background-color: #0056b3;
}

.btn-delete {
  background-color: #dc3545;
}

.no-data {
  text-align: center;
  font-style: italic;
  color: #888;
}

.btn-add {
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  margin-bottom: 20px;
  cursor: pointer;
}

.btn-add:hover {
  background-color: #218838;
}

/* Modal stilleri */
.modal {
  display: block;
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
}

.modal-content {
  background-color: #fefefe;
  margin: 10% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  border-radius: 8px;
  position: relative;
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  cursor: pointer;
}

.customer-form {
  display: flex;
  flex-direction: column;
}

.form-group {
  margin-bottom: 20px;
}

label {
  font-weight: bold;
}

input[type="text"] {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

.btn {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  font-size: 16px;
}

.btn-primary {
  background-color: #007bff;
}

.btn-primary:hover {
  background-color: #0056b3;
}

h1 {
  margin-bottom: 20px;
  color: #333;
}
</style>
