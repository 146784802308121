import { createApp } from 'vue';
import App from './App.vue';
import { createRouter, createWebHistory } from 'vue-router';
import CustomerList from '../components/CustomerList.vue';
import CustomerForm from '../components/CustomerForm.vue';

const routes = [
    {
        path: '/',
        name: 'CustomerList',
        component: CustomerList
    },
    {
        path: '/customer/edit/:id',
        name: 'CustomerForm',
        component: CustomerForm,
        props: true
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes
});

const app = createApp(App);
app.use(router);

app.mount('#app');
