<template>
  <div class="customer-form-container">
    <form @submit.prevent="saveCustomer" class="customer-form">
      <input type="hidden" id="id" v-model="formData.id" />
      <div class="form-group">
        <label for="firstName">Ad:</label>
        <input type="text" id="firstName" v-model="formData.firstName" class="form-control" />
      </div>
      <div class="form-group">
        <label for="lastName">Soyad:</label>
        <input type="text" id="lastName" v-model="formData.lastName" class="form-control" />
      </div>
      <div class="form-group">
        <label for="identificationNumber">Kimlik No:</label>
        <input type="text" id="identificationNumber" v-model="formData.identificationNumber" class="form-control" />
      </div>
      <button type="submit" class="btn btn-primary">Kaydet</button>
    </form>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: 'CustomerForm',
  props: {
    id: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      formData: {
        id: this.id,
        firstName: '',
        lastName: '',
        identificationNumber: ''
      }
    };
  },
  created() {
    this.fetchCustomer(this.id);
  },
  methods: {
    fetchCustomer(customerId) {
      axios.get(`http://neogoation.mertay.me/api/api/customer/${customerId}`)
          .then(response => {
            this.formData = response.data;
          })
          .catch(error => {
            console.error('Müşteri bilgilerini getirme başarısız:', error);
          });
    },
    saveCustomer() {
      axios.put(`http://neogoation.mertay.me/api/api/customer/${this.id}`, this.formData)
          .then(() => {
            this.$router.push('/');
          })
          .catch(error => {
            console.error('Düzenleme işlemi başarısız:', error);
          });
    }
  }
};
</script>

<style scoped>
.customer-form-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.customer-form {
  display: flex;
  flex-direction: column;
}

.form-group {
  margin-bottom: 20px;
}

label {
  font-weight: bold;
}

input[type="text"] {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

.btn {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  font-size: 16px;
}

.btn-primary {
  background-color: #007bff;
}

.btn-primary:hover {
  background-color: #0056b3;
}

h1 {
  margin-bottom: 20px;
  color: #333;
}
</style>
