<template>
  <div id="app">
    <header>
      <h1>Customer Management System</h1>
      <nav>
        <router-link to="/">Main Page</router-link>
      </nav>
    </header>
    <main>
      <router-view/>
    </main>

  </div>
</template>

<script>
export default {
  name: 'App'
};
</script>

<style>
#app {
  font-family: 'Arial', sans-serif;
  text-align: center;
  color: #333;
}

nav {
  background-color: #f8f9fa;
  padding: 10px;
  margin-bottom: 20px;
}

nav a {
  margin-right: 10px;
  text-decoration: none;
  color: #333;
  font-weight: bold;
}

nav a{
  color: #007bff;
}
</style>